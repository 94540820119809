import {all, takeEvery, put, fork, call} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {getToken, clearToken, nukleon, handleError} from '../../helpers/utility';
import actions from './actions';
import {notification as antdnotify} from 'antd';

function* authorize(token) {
    try {
        return yield nukleon.get(`/token/${token}`);
    } catch (err) {
        if (!err.response) {
            return;
        }
        antdnotify.error({
            key: 'tokenError',
            message: 'Token Expired! Please logout and login again.'
        });
        yield put({type: actions.LOGOUT});
    }
}

export function* loginRequest() {
    yield takeEvery('LOGIN_REQUEST', function* ({values, history}) {
        try {
            let token_res = yield nukleon.get('token', {
                headers: values
            })
            let user_res = yield call(authorize, token_res.data.token);
            nukleon.defaults.headers.common['Authorization'] = `Bearer ${token_res.data.token}`;
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: token_res.data.token,
                user: user_res.data,
                account: user_res.data.accounts.length ? user_res.data.accounts[0].id : null,
                history
            });
        } catch (err) {
            console.log(err);
            handleError(err);
            yield put({type: actions.LOGIN_ERROR});
        }

    });
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* ({token, user, account, history}) {
        yield localStorage.setItem('nukleon_token', token);
        yield localStorage.setItem('nukleon_user', JSON.stringify(user));
        if (history) history.push('/');
    });
}

export function* updateUser() {
    yield takeEvery(actions.UPDATE_USER, function* ({user}) {
        yield localStorage.setItem('nukleon_user', JSON.stringify(user));
    });
}

export function* switchAccount() {
    yield takeEvery(actions.SWITCH_ACCOUNT, function* ({account}) {
        yield localStorage.setItem('nukleon_account', account);
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {

    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        clearToken();
        yield put(push('/'));
    });
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const token = getToken().get('idToken') === "null" ? null : getToken().get('idToken');
        const user = getToken().get('user');
        if (token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token,
                user,
                // account
            });
            let user_res = yield call(authorize, token);
            if (user_res) {
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token,
                    user: user_res.data,
                });
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(updateUser),
        fork(switchAccount),
        fork(logout)
    ]);
}
