import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {ConfigProvider, Layout} from 'antd';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import rolesActions from '../../redux/roles/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig } from '../../config.js';
import themes from '../../config/themes';
import { themeConfig } from '../../config';
import AppHolder from './commonStyle';
import './global.css';
import { AppLocale } from '../../dashApp';
import { IntlProvider } from 'react-intl';
import {useRouteMatch} from "react-router-dom";

const { Content, Footer } = Layout;
const App = (props) => {

    const match = useRouteMatch();

    useEffect(() => {
        props.getRoles();
    }, [])

    const locale = props.user.language.toLowerCase() || props.locale;
    const currentAppLocale = AppLocale[locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
            <ThemeProvider theme={themes[themeConfig.theme]}>
              <AppHolder>
                <Layout style={{ height: '100vh' }}>
                  <Topbar url={match.url} />
                  <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                    <Sidebar url={match.url} />
                    <Layout
                      className="isoContentMainLayout"
                      style={{
                        height: '100vh'
                      }}
                    >
                      <Content
                        className="isomorphicContent"
                        style={{
                          padding: '70px 0 0',
                          flexShrink: '0',
                          background: '#f1f3f6'
                        }}
                      >
                        <AppRouter hasAccounts={props.selected_account !== null} url={match.url} />
                      </Content>
                      <Footer
                        style={{
                          background: '#ffffff',
                          textAlign: 'center',
                          borderTop: '1px solid #ededed'
                        }}
                      >
                        {siteConfig.footerText}
                      </Footer>
                    </Layout>
                  </Layout>
                </Layout>
              </AppHolder>
            </ThemeProvider>
          </IntlProvider>
        </ConfigProvider>
    );
}

export default connect(
  state => ({
    user: state.Auth.toJS().user,
    locale: state.LanguageSwitcher.toJS().language.locale,
  }),
  { logout: authAction.logout, toggleAll: appActions.toggleAll, getRoles: rolesActions.getRoles }
)(App);
