import React from 'react';
import { connect } from 'react-redux';
import { Layout, Breadcrumb, Tooltip } from 'antd';
import appActions from '../../redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import themes from '../../config/themes';
import { injectIntl } from 'react-intl';
import { themeConfig } from '../../config';
import IntlMessages from '../../components/utility/intlMessages';
import {Link} from "react-router-dom";

const { Header } = Layout;
const customizedTheme = themes[themeConfig.theme];

const Topbar = (props) => {
    let { toggleCollapsed, locale, user, selected_account} = props;
    locale = user.language.toLowerCase() || locale;
    const collapsed = props.collapsed && !props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
            
            {selected_account !== null ?
              <Breadcrumb style={{marginLeft: 15}}>
                {
                  props.breadcrumbs.map(breadcrumb => {
                      let link = breadcrumb.link;
                      let text = breadcrumb.id ? <IntlMessages id={breadcrumb.id} /> : breadcrumb.text;
                      if(link) {
                        return (
                          <Breadcrumb.Item key={breadcrumb.id || breadcrumb.text}>
                            <Link to={link}>{text}</Link>
                          </Breadcrumb.Item>
                        )
                      }
                      return <Breadcrumb.Item key={breadcrumb.id || breadcrumb.text}>{text}</Breadcrumb.Item>;
                    }
                  )
                }
              </Breadcrumb> :
              <p style={{margin: '0 0 0 15px'}}><IntlMessages id="breadcrumbs.noAccounts" /></p>
            }
          </div>

          <ul className="isoRight">
            <li>
              <Link to={`/search`}>
                <Tooltip placement="bottom" title={<IntlMessages id="topbar.search" />}>
                  <i className="ion-ios-search-strong"/>
                </Tooltip>
              </Link>
            </li>
            <li className="isoUser">
              <TopbarUser locale={locale} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
}

export default connect(
  state => { 
    return({
    toggleCollapsed: state.App.get('toggleCollapsed'),
    collapsed: state.App.get('collapsed'),
    openDrawer: state.App.get('openDrawer'),
    user: state.Auth.get('user'),
    locale: state.LanguageSwitcher.get('language').locale,
    breadcrumbs: state.Breadcrumbs,
  })},
  { toggleCollapsed: appActions.toggleCollapsed }
)(injectIntl(Topbar));
