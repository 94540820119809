import React, { Component } from "react";
import { connect } from "react-redux";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import {hasRoles} from "../../helpers/utility";

const { logout } = authAction;

class TopbarUser extends Component {
    constructor(props) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false
        };
    }
    hide() {
        this.setState({ visible: false });
    }
    handleVisibleChange() {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const content = (
            <TopbarDropdownWrapper className="isoUserDropdown">
                {hasRoles(['Reporter', 'Administrator'], this.props.user) && <Link
                    to={{ pathname: `/settings/systems` }}
                    onClick={this.hide}
                    className="isoDropdownLink"
                >
                    <IntlMessages id="userMenu.settings" />
                </Link>}
                <Link
                    to={{ pathname: `/profile` }}
                    onClick={this.hide}
                    className="isoDropdownLink"
                >
                    <IntlMessages id="userMenu.editProfile" />
                </Link>
                <a className="isoDropdownLink" onClick={this.props.logout}>
                    <IntlMessages id="userMenu.logout" />
                </a>
            </TopbarDropdownWrapper>
        );

        return (
            <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter={true}
                placement="bottomLeft"
            >
                <div className="isoImgWrapper">
                    <Avatar size="large">
                        {this.props.user.firstname.charAt(0)}
                        {this.props.user.lastname.charAt(0)}
                    </Avatar>
                </div>
            </Popover>
        );
    }
}
export default connect(
    state => {
        return {
            user: state.Auth.get("user")
        };
    },
    { logout }
)(TopbarUser);
