import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { nukleon,  } from '../../helpers/utility';
import actions from './actions';

export function* getRoles() {
    yield takeEvery(actions.GET_ROLES, function*() {
		try {
            let roles_res = yield nukleon.get('role');
			yield put({ type: actions.PUT_ROLES, roles: roles_res.data });
		} catch (err) {
			// handleError(err);
		}  
    });
}

export default function* rootSaga() {
	yield all([
		fork(getRoles)
	]);
}
