const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  UPDATE_USER: 'UPDATE_USER',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SWITCH_ACCOUNT: 'SWITCH_ACCOUNT',
  switchAccount: (account) => ({ type: actions.SWITCH_ACCOUNT, account }),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  updateUser: (user) => ({ type: actions.UPDATE_USER, user }),
  login: ({values, history}) => ({
    type: actions.LOGIN_REQUEST,
    values,
    history
  }),
  logout: () => ({
    type: actions.LOGOUT
  })
};
export default actions;
