import React, {useEffect} from "react";
import {Switch, Route, useLocation} from "react-router-dom";
import {connect} from "react-redux";

import breadcrumbActions from "../../redux/breadcrumbs/actions";
import appActions from "../../redux/app/actions";
import loadable from "@loadable/component";

const EditProfile = loadable(() => import("../editProfile"));
const Settings = loadable(() => import("../Settings/settings"));
const Library = loadable(() => import("../library"));
const Favourites = loadable(() => import("../Items/favourites"));
const NewItem = loadable(() => import("../Items/newItem"));
const Items = loadable(() => import("../Items/items"));
const Sites = loadable(() => import("../Sites/sites"));
const NewSite = loadable(() => import("../Sites/newSite"));
const Home = loadable(() => import("../home"));
const ComingSoon = loadable(() => import("../comingSoon"));
const Search = loadable(() => import("../search"));

const AppRouter = (props) => {

    const location = useLocation();

    useEffect(() => {
        if (!(location.pathname.includes("/items")) || !(location.pathname.includes("/sites"))) {
            props.changeCurrent([
                location.pathname.replace("/", "") || "home"
            ]);
        }

    }, [location]);

    return (
        <Switch>
            <Route
                exact
                path={`/profile`}
                children={<EditProfile/>}
            />
            <Route
                path={`/settings`}
                children={<Settings/>}
            />
            <Route
                exact
                path={`/library`}
                children={<Library/>}
            />
            <Route
                exact
                path={`/favourites`}
                children={<Favourites/>}
            />
            <Route
                path={`/items/add/`}
                children={<NewItem/>}
            />
            <Route path={`/items/edit/:id`}
                   children={<NewItem/>}/>
            <Route
                path={`/items`}
                children={<Items/>}
            />
            <Route
                exact
                path={`/sites`}
                children={<Sites/>}
            />
            <Route
                exact
                path={`/sites/add`}
                children={<NewSite/>}
            />
            <Route path={`/sites/edit/:id`} children={<NewSite/>}/>
            <Route
                exact
                path={`/`}
                children={<Home/>}
            />
            <Route
                exact
                path={`/comingsoon`}
                children={<ComingSoon/>}
            />
            <Route
                exact
                path={`/search`}
                children={<Search/>}
            />
        </Switch>
    );
}

export default connect(
    null,
    {clearBreadcrumbs: breadcrumbActions.clearBreadcrumbs, changeCurrent: appActions.changeCurrent}
)(AppRouter);
