import actions from './actions';

const initState = [];

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.PUT_ROLES:
      return [...action.roles];
    default:
      return state;
  }
}
