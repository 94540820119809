import actions from "./actions";

const initState = { selected_columns: ["funcs", "materials"], sort: "" };

export default function itemsColumnsReducer(state = initState, action) {
    switch (action.type) {
        case actions.UPDATE_ITEMS_COLUMNS:
            return {...state, selected_columns: [...action.columns]};
        case actions.SET_ITEMS_SORT:
            return {...state, sort: action.sort};
        default:
            return state;
    }
}
