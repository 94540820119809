import styled from "styled-components";
import { palette } from "styled-theme";

const AppHolder = styled.div`
	.trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0 16px;
		cursor: pointer;
		transition: color 0.3s;
	}

	.trigger:hover {
		color: ${palette("primary", 0)};
	}

	.ant-layout-sider-collapsed .anticon {
		font-size: 16px;
	}
	.upload_cover {
		margin-bottom: 40px;
        overflow: hidden;
		.ant-upload.ant-upload-select {
			width: 100%;
			padding: 20px;
		}
	}

	.sites_bar {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}

	.loading_site_thumbnails {
		opacity: 0.4;
		pointer-events: none;
	}

	.inline-block {
		display: inline-block;
	}

	.site_thumbnails {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		.site_thumbnails_item {
			width: 33.33333%;
			padding: 15px;
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
			.site_thumbnails_image {
				padding-top: 60%;
				overflow: hidden;
				position: relative;
				background: #e8e8e8;
				margin-bottom: 10px;
				.no_image {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					color: #888;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.site_thumbnails_title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 16px;
			}
		}
	}

	.ant-layout-sider-collapsed .nav-text {
		display: none;
	}

	.ant-layout {
		background: ${palette("secondary", 1)};

		&.isoContentMainLayout {
			overflow: auto;
			overflow-x: hidden;
			@media only screen and (min-width: 768px) and (max-width: 1220px) {
				width: calc(100% - 64px);
				flex-shrink: 0;
			}

			@media only screen and (max-width: 767px) {
				width: 100%;
				flex-shrink: 0;
			}
		}
	}

	.isoLayoutContent {
		width: 100%;
		padding: 35px;
		background-color: #ffffff;
		border: 1px solid ${palette("border", 0)};
		height: 100%;
	}
	.isoContentMainLayout {
		-webkit-overflow-scrolling: touch;
	}

	.isomorphicLayout {
		width: calc(100% - 240px);
		flex-shrink: 0;
		overflow-x: hidden !important;

		@media only screen and (max-width: 767px) {
			width: 100%;
		}

		@media only screen and (min-width: 768px) and (max-width: 1220px) {
			width: calc(100% - 64px);
		}
	}

	.ant-layout-footer {
		font-size: 13px;
		@media (max-width: 767px) {
			padding: 10px 20px;
		}
	}

	button {
		//border-radius: 0;
	}

	.settings_title {
		display: flex;
		margin-bottom: 20px;
		justify-content: space-between;
		> div {
			margin-right: 20px;
		}
		h1 {
			font-size: 24px;
			margin-bottom: 5px;
		}
	}
	
	.responsive-table {
		.ant-table-body {
			overflow: auto;
		}
		ant-table-thead > tr > th, .ant-table-tbody > tr > td {
			min-width: 120px;
		}
	}

	.plans-table {
		.ant-table-body {
			overflow: auto;
			.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
				&:nth-child(1) {
					width: 80px;
					min-width: 80px;
				}
				&:nth-child(2) {
					min-width: 200px;
				}
				&:nth-child(3) {
					width: 125px;
					min-width: 125px;
				}
				&:nth-child(4) {
					width: 200px;
					min-width: 200px;
				}
				&:nth-child(5) {
					width: 150px;
					min-width: 150px;
				}
				&:nth-child(6) {
					width: 315px;
					min-width: 315px;
				}
			}
		}
	}

	.files-table {
		.ant-table-body {
			overflow: auto;
			.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
				&:nth-child(1) {
					min-width: 250px;
				}
				&:nth-child(2) {
					width: 125px;
					min-width: 125px;
				}
				&:nth-child(3) {
					width: 200px;
					min-width: 200px;
				}
				&:nth-child(4) {
					width: 150px;
					min-width: 150px;
				}
				&:nth-child(5) {
					width: 315px;
					min-width: 315px;
				}
			}
		}
	}

  .results_section {
    margin-bottom: 16px;
    position: relative;
    .ant-pagination.ant-table-pagination {
      float: left;
    }
    .results_section_add_new_nopag {
      margin-top: 16px;
      text-align: right;
    }
    .results_section_add_new {
      position: absolute;
      right: 0;
      bottom: 16px;
      @media only screen and (max-width: 520px) {
        position: relative;
        right: auto;
        bottom: auto;
      }
      &.no_items {
        position: relative;
        bottom: auto;
        margin-top: 16px;
        text-align: right;
      }
    }
  }

	.picture-offline {
		cursor: not-allowed;
		.ant-upload-list-item-actions {
			display: none;
		}
		.ant-upload-list-item-info:before {
			content: none;
		}
		.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
			pointer-events: none;
			opacity: 0.7;
			cursor: not-allowed;
		}
	}

	.ant-upload-list-picture-card .ant-upload-list-item, .ant-upload.ant-upload-select-picture-card {
		float: left;
		width: 55px;
		height: 55px;
		margin: 0 3px 3px 0;
		padding: 2px;
		@media only screen and (max-width: 520px) {
			width: 100%;
			height: auto;
		}
	}


	.ant-upload-list-picture-card .ant-upload-list-item-progress {
		padding-left: 0;
		bottom: 0;
		width: 100%;
		padding: 0 5px;
		left: 0;
		top: 0;
		height: 21px;
		margin: auto;
	}
	.ant-upload-list-picture-card .anticon-cross {
		right: 3px;
		top: 3px;
	}

	.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
		display: none;
	}

	.results_table {
		.ant-table-body {
			overflow: auto;
			.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
				&:nth-child(1) {
					min-width: 150px;
				}
				&:nth-child(2) {
					min-width: 200px;
				}
				&:nth-child(3) {
					min-width: 150px;
				}
				&:nth-child(4) {
					min-width: 150px;
				}
				&:nth-child(5) {
					min-width: 150px;
				}
			}
		}
	}

	.history-table {
		.ant-table-body {
			overflow: auto;
			.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
				&:nth-child(1) {
					width: 150px;
					min-width: 150px;
				}
				&:nth-child(2) {
					width: 150px;
					min-width: 150px;
				}
				&:nth-child(3) {
					width: 200px;
					min-width: 200px;
				}
				&:nth-child(4) {
					width: 150px;
					min-width: 150px;
				}
				&:nth-child(5) {
					width: 150px;
					min-width: 150px;
				}
			}
		}
	}
		
 	.hoods_grid_wrap {
		max-width: 600px;
		min-width: 600px;
		margin: 0 auto;
		position: relative;
		padding-left: 80px;
		padding-top: 80px;
		.hoods_grid_width {
			position: absolute;
			top: 0px;
			left: 80px;
			right: 0px;
			display: flex;
			height: 60px;
			flex-direction: column;
			align-items: center;
			.hoods_grid_width_line {
				width: 100%;
				height: 1px;
				background: #000;
				margin-top: 10px;
				position: relative;
				&:after, &:before {
					content: '';
					width: 1px;
					height: 11px;
					background: #000;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
				&:after {
					left: 0;
				}
				&:before {
					right: 0;
				}
			}
		}
	}
	.hoods_grid_height {
		position: absolute;
		top: 80px;
		left: 0;
		bottom: 0;
		display: flex;
		width: 60px;
		align-items: center;
		.hoods_grid_height_line {
			width: 1px;
			height: 100%;
			background: #000;
			margin-left: 10px;
			position: relative;
			&:after, &:before {
				content: '';
				width: 11px;
				height: 1px;
				background: #000;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
			&:after {
				top: 0;
			}
			&:before {
				bottom: 0;
			}
		}
	}
	.hoods_grid {
		display: flex;
		flex-wrap: wrap;
		border: 1px solid #909090;
	}
	.hoods_grid_item {
		display: flex;
		min-width: 33.3333%;
		width: 33.33%;
		align-items: center;
		padding: 15px;
		.ant-form-item {
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			.ant-form-item-label {
				padding: 0;
				min-width: 13px;
			}
			.ant-form-item-control-wrapper {
				margin: 0 10px;
			}
		}
	}
	}

	.sigWrapper {
		max-width: 600px;
		border: 1px solid #ccc;
	}
	
	.sigCanvas {
		width: 100%;
		height: 300px;
	}

	.sync-popover {
		left: auto;
		right: -12px;
		top: 28px
	}

	.home-buttons {
		margin: 30px 15px;
		display: flex;
		justify-content: space-around;
		width: 100%;
		@media only screen and (max-width: 520px) {
			flex-direction: column;
			> div {
				margin-bottom: 30px;
			}
		}
	}
	.ant-btn-home {
		font-size: 76px;
		height: 130px;
		width: 130px;
		line-height: 130px;
		padding: 0;
		color: #fff;
		display: block;
		margin: 0 auto;
		img {
			width: 66px;
			margin-top: -13px;
		}
		&:hover {
			// background-color: #fafafa !important;
			color: #fff !important;
		}
		@media only screen and (max-width: 520px) {
		
			// font-size: 32px;
			// height: 62px;
			// width: 62px;
			// line-height: 62px;
			// padding: 0;
		}
	}
	.ant-btn-home-text {
			text-align: center;
			font-size: 22px;
			margin-top: 10px;
			font-weight: 500;
		@media only screen and (max-width: 520px) {
			font-size: 16px;
		}
	}
	
	@media only screen and (max-width: 520px) {
		.ant-list-item {
			flex-direction: column;
			align-items: flex-start;
		}
		.ant-list-item-meta {
			margin-bottom: 15px;
		}
		.ant-list-item-action {
			margin: 30px 0 0 0;
		}
		.sync-popover {
			left: 10px;
			right: 10px;
			margin: auto;
			position: fixed;
			top: 60px;
		}
	}

	.ant-list-item.title {
		border-bottom: 2px solid #dcdcdc;
		display: none;
		@media only screen and (max-width: 520px) {
			display: none;
		}
		h4 {
			margin-bottom: 0;
		}
		.ant-list-item-action {
			.offline {
				width: 52px
			}
			.edit {
				width: 71.7px
			}
			.delete {
				width: 87.4px     
			}
		}
	}

    .react-resizable {
      position: relative;
      background-clip: padding-box;
    }
    
    .react-resizable-handle {
      position: absolute;
      right: -5px;
      bottom: 0;
      z-index: 1;
      width: 10px;
      height: 100%;
      cursor: col-resize;
    }

	.inline-form-field {
		display: flex;
		input {
			max-width: 100%;
			width: 220px;
		}
		.ant-form-item-label {
			text-align: left;
		}
		@media only screen and (max-width: 767px) {
			flex-direction: column;
		}
	}

	.form-group {
		border: 1px solid #eaeaea;
		margin-bottom: 20px;
		padding: 20px;
		margin-top: 20px;
		background: #fafafa;
	}


	.plan-upload .has-success.has-feedback:after {
		height: 32px;
	}

	.has-success.has-feedback:after, .has-warning.has-feedback:after, .has-error.has-feedback:after, .is-validating.has-feedback:after {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.has-error.has-feedback:after {
		display: none;
	}

	.asset_form_nav {
		padding: 0;
		width: 100%;
		list-style: none;
		// margin: 0 17px 30px 17px;
		border-bottom: 1px solid #d6d6d6;
		white-space: nowrap;
		li {
			display: inline-block;
			span {
				margin-left: 5px;
			}
			i {
				
			}
			a {
				color: #2e3545;
				margin: 0;
				border: 1px solid #d6d6d6;
				border-bottom: 0;
				border-radius: 4px 4px 0 0;
				background: #e8eaec;
				display: inline-block;
				margin: 0 20px 0 0;
				padding: 12px 16px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				position: relative;
				-webkit-transition: color .3s cubic-bezier(.645,.045,.355,1);
				-o-transition: color .3s cubic-bezier(.645,.045,.355,1);
				transition: color .3s cubic-bezier(.645,.045,.355,1);
				cursor: pointer;
				text-decoration: none;
				&.active {
					background: #f1f3f6;
					color: #1890ff;
					font-weight: 500;
					&:after {
						content: '';
						width: 100%;
						height: 1px;
						background-color: #f1f3f6;
						position: absolute;
						bottom: -1px;
						left: 0;
					}
				}
				&:hover {
					color: #1890ff;
				}
				&[disabled] {
					opacity: 0.5;
				}
			}
		}
	}

    .table-disabled-row {
      background-color: #fbfbfb;
    }
    
	.dashboards_boxes {
		display: flex;
		width: 100%;
		max-width: 600px;
		flex-wrap: wrap;
		margin: 0 auto;
		.dashboards_box {
			background-color: #ba8cd6;
			border: 1px solid #9062ad;
			border-radius: 3px;
			display: flex;
			width: calc(300px - 20px);
			margin: 10px;
			.dashboards_box_icon {
				background-color: #9062ad;
				display: flex;
				align-items: center;
				padding: 20px;
				font-size: 22px;
				color: #fff;
			}
			.dashboards_box_content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				color: #fff;
				font-weight: 500;
				line-height: 1.4;
				padding: 15px 15px;
				
			}
			&.red {
				background-color: #ea6d6d;
				border: 1px solid #bd2626;
				.dashboards_box_icon {
					background-color: #bd2626;
				}
			}
			&.green {
				background-color: #7bd086;
				border: 1px solid #51985a;
				.dashboards_box_icon {
					background-color: #51985a;
				}
			}
			&.yellow {
				background-color: #f3c68c;
				border: 1px solid #e29a3e;
				.dashboards_box_icon {
					background-color: #e29a3e;
				}
			}
		}
	}
	

		
}
`;

export default AppHolder;
