import React from 'react';
import { Link } from 'react-router-dom';
import icon from '../../image/icon.png';
import logo from '../../image/logo_white.png';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/">
              <img style={{height: '32px'}} src={icon} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/"><img style={{height: '64px'}} src={logo} /></Link>
        </h3>
      )}
    </div>
  );
};
