import actions from './actions';

const initState = [];

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_BREADCRUMBS:
      return action.breadcrumbs;
    case actions.CLEAR_BREADCRUMBS:
      return [];
    default:
      return state;
  }
}
