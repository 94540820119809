const actions = {
    GET_ROLES: 'GET_ROLES',
    PUT_ROLES: 'PUT_ROLES',
    getRoles: () => ({
      type: actions.GET_ROLES
    }),
    putRoles: (roles) => ({
      type: actions.PUT_ROLES,
      roles
    }),
  };
  export default actions;
  