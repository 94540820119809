import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import 'antd/dist/antd.css';
import './css/ionicons.min.css';
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<DashApp />, document.getElementById('root'));

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./dashApp.js', () => {
    const NextApp = require('./dashApp').default;
    ReactDOM.render(
        <React.StrictMode>
          <NextApp />
        </React.StrictMode>,
        document.getElementById('root'));
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
