import Auth from "./auth/reducer";
import App from "./app/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import Breadcrumbs from "./breadcrumbs/reducer";
import Roles from "./roles/reducer";
import ItemsTableColumns from "./itemsTableColumns/reducer";

export default {
    Auth,
    App,
    LanguageSwitcher,
    Breadcrumbs,
    Roles,
    ItemsTableColumns
};
