const actions = {
    UPDATE_ITEMS_COLUMNS: "UPDATE_ITEMS_COLUMNS",
    updateItemsColumns: columns => ({
        type: actions.UPDATE_ITEMS_COLUMNS,
        columns
    }),
    SET_ITEMS_SORT: "SET_ITEMS_SORT",
    setItemSort: sort => ({
        type: actions.SET_ITEMS_SORT,
        sort
    })
};
export default actions;
