import React from 'react';
import {Route, Redirect, Switch, useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import loadable from '@loadable/component'

import App from './containers/App/App';
import {ConnectedRouter} from "connected-react-router";

const RequireAuth = ({ children, isLoggedIn }) => {
    const location = useLocation();
    return isLoggedIn ? children : <Redirect to={{pathname: '/signin', state: {from: location}}}/>
}

const Signin = loadable(() => import('./containers/Page/signin'));
const ViewPdf = loadable(() => import("./containers/viewPdf"));

const PublicRoutes = ({ history, isLoggedIn }) => {
  window.appHistory = history;
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path={'/signin'}
          children={<Signin/>}
        />
          <Route
              path={`/library/view/:id`}
              children={<RequireAuth isLoggedIn={isLoggedIn}><ViewPdf/></RequireAuth>}
          />
        <Route
          path={`/`}
          children={<RequireAuth isLoggedIn={isLoggedIn}><App/></RequireAuth>}
        />
      </Switch>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.get('idToken') !== null,
}))(PublicRoutes);
