import Enlang from './entries/en-US';
import Nllang from './entries/nl_NL';
import Frlang from './entries/fr_FR';

const AppLocale = {
  en: Enlang,
  nl: Nllang,
  fr: Frlang
};
require('@formatjs/intl-pluralrules/polyfill')
require('@formatjs/intl-pluralrules/locale-data/en') // Add locale data for de
require('@formatjs/intl-pluralrules/locale-data/fr') // Add locale data for fr
require('@formatjs/intl-pluralrules/locale-data/nl') // Add locale data for nl

require('@formatjs/intl-relativetimeformat/polyfill')
require('@formatjs/intl-relativetimeformat/locale-data/en') // Add locale data for en
require('@formatjs/intl-relativetimeformat/locale-data/fr') // Add locale data for fr
require('@formatjs/intl-relativetimeformat/locale-data/nl') // Add locale data for nl

export default AppLocale;
