const actions = {
    SET_BREADCRUMBS: 'SET_BREADCRUMBS',
    CLEAR_BREADCRUMBS: 'CLEAR_BREADCRUMBS',
    setBreadcrumbs: (breadcrumbs) => ({
      type: actions.SET_BREADCRUMBS,
      breadcrumbs
    }),
    clearBreadcrumbs: () => ({
      type: actions.CLEAR_BREADCRUMBS
    }),
   
  };
  export default actions;
  