import moment from "moment";

export const LIST_DECRIPTION_NATURE = ["intervention", "observation"];
export const LIST_GENERIC_TIMECODE = ["am", "pm", "night", "day"];
export const LIST_FORK_UNIT = ["DAYS", "MONTHS", "YEARS", "CENTURIES"];
export const LIST_TEMPORAL_DATA_TYPE = ["FULL_ABSOLUTE", "PARTIAL_ABSOLUTE", "CENTRAL", "RELATIONAL"];
export const LIST_PARTIAL_ABSOLUTE = ["APPROX_YEAR", "BETWEEN_YEARS", "AFTER_YEAR", "BEFORE_YEAR", "DURING_YEAR"];
export const LIST_DATING_ACCURACY = ["0", "1", "2", "3", "4", "5"];
export const LIST_DATING_REFERENCE = ["HISTORICAL_SOURCE", "STRATIGRAPHIC_SOURCE", "DENDROCHRONOLOGY_SOURCE", "CARBON_14", "COMPARATIVE_SOURCE"];

export const MILISECOND_IN_DAY = 86400000;
export const ZOOM_MIN = 1000 * 60 * 24;

export const NEW_EVENT = {
    _id: 0,
    start_date: moment(),
    end_date: moment().add(1, "day"),
    start: moment().format("YYYY-MM-DD"),
    end: moment().add(1, "day").format("YYYY-MM-DD"),
    short_title: "",
    description_nature: "",
    detailed_description: "",
    thematic: undefined,
    function: undefined,
    dating_accuracy: "",
    dating_hypothesis: "",
    dating_references: [],
    items: [],
    impact_parents: false,
    interior_climate: "",
    exterior_climate: "",
    temporal_type: "FULL_ABSOLUTE",
    timeDataType: "event"
};

export const OPTIONS_TIMELINE = {
    width: "100%",
    minHeight: "230px",
    editable: false,
    multiselect: false,
    orientation: "bottom",
    locale: 'en',
    zoomMin: ZOOM_MIN,
};

export const STATE_GROUP_ID = 2;
export const EVENT_GROUP_ID = 1;

export const HIDED_EVENT_LIST = [
    {
        ...NEW_EVENT,
        style: "display: none;"
    }
];

export const HIDED_STATE_LIST = [
    {
        ...NEW_EVENT,
        _id: 1,
        style: "display: none;"
    }
];

export const LIST_GROUPS = [
    {
        id: EVENT_GROUP_ID,
        content: "EVENTS",
    },
    {
        id: STATE_GROUP_ID,
        content: "STATES",
        className: "vis-state-row"
    }
];

export const NEW_DOCUMENT = {
    reference: "",
    file_in: false,
    reference_photo: "",
    source: "Source",
    photos: false
};