import {Map} from "immutable";
import config from "../config";
import axios from "axios";
import moment from "moment";
import notification from "../components/notification";
import {notification as antdnotify} from "antd";
import {store, history} from "../redux/store";
import {MILISECOND_IN_DAY} from "./constants";
import authActions from "../redux/auth/actions";
import {getYear, getHours} from "date-fns";

const AUTH_TOKEN = localStorage.getItem("nukleon_token");
let nukleon = axios.create({
    baseURL: config.apiUrl
});
if (AUTH_TOKEN) {
    nukleon.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
}
export {nukleon};

export const isAdmin = _user => {
    const user = _user || store.getState().Auth.toJS().user;
    if (!user.roles) return false;
    return user.roles.some(role => role.name === "Administrator");
};
export const isReporter = _user => {
    const user = _user || store.getState().Auth.toJS().user;
    if (!user.roles) return false;
    return user.roles.some(role => role.name === "Reporter");
};
export const isReader = _user => {
    const user = _user || store.getState().Auth.toJS().user;
    if (!user.roles) return false;
    return user.roles.some(role => role.name === "Reader");
};
export const hasRoles = (roles, _user, all = false) => {
    const user = _user || store.getState().Auth.toJS().user;
    if (!user.roles) return false;
    return roles[all ? "every" : "some"](role =>
        user.roles.map(r => r.name).includes(role)
    );
};

export const userCan = (cap, _user) => {
    if (Array.isArray(cap)) {
        return cap.every(c => userCan(c, _user));
    }
    switch (cap) {
        case "add_users":
            return hasRoles(["Administrator"], _user);

        case "edit_users":
            return hasRoles(["Administrator"], _user);

        case "delete_users":
            return hasRoles(["Administrator"], _user);

        case "add_systems":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "edit_systems":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "delete_systems":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "delete_thematic":
            return hasRoles(["Administrator"], _user);

        case "edit_thematic":
            return hasRoles(["Administrator"], _user);

        case "add_thematic":
            return hasRoles(["Administrator"], _user);

        case "delete_function":
            return hasRoles(["Administrator"], _user);

        case "edit_function":
            return hasRoles(["Administrator"], _user);

        case "add_function":
            return hasRoles(["Administrator"], _user);

        case "delete_item_manager":
            return hasRoles(["Administrator"], _user);

        case "edit_item_manager":
            return hasRoles(["Administrator"], _user);

        case "add_item_manager":
            return hasRoles(["Administrator"], _user);

        case "delete_warehouse":
            return hasRoles(["Administrator"], _user);

        case "edit_warehouse":
            return hasRoles(["Administrator"], _user);

        case "add_warehouse":
            return hasRoles(["Administrator"], _user);

        case "delete_source_type":
            return hasRoles(["Administrator"], _user);

        case "edit_source_type":
            return hasRoles(["Administrator"], _user);

        case "add_source_type":
            return hasRoles(["Administrator"], _user);

        case "delete_dating_reference_source":
            return hasRoles(["Administrator"], _user);

        case "edit_dating_reference_source":
            return hasRoles(["Administrator"], _user);

        case "add_dating_reference_source":
            return hasRoles(["Administrator"], _user);

        case "delete_material":
            return hasRoles(["Administrator"], _user);

        case "edit_material":
            return hasRoles(["Administrator"], _user);

        case "add_material":
            return hasRoles(["Administrator"], _user);

        case "delete_sites":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "edit_sites":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "add_sites":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "delete_items":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "edit_items":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "add_items":
            return hasRoles(["Administrator", "Reporter"], _user);

        case "edit_library":
            return hasRoles(["Administrator"], _user);

        default:
            return false;
    }
};

export const formatBytes = (bytes, decimals) => {
    if (bytes == 0) return "0 Bytes";
    var k = 1024,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const handleSuccess = (response) => {
    /*if (!formatMessage) {
        formatMessage = (obj) => obj.defaultMessage;
    }*/
    notification("success", response.data.message);
};

export const handleError = (error, formatMessage) => {
    console.log(error);
    if (!formatMessage) {
        formatMessage = (obj) => obj.defaultMessage;
    }
    if (!error.response) {
        notification("error", formatMessage({
            id: "networkError",
            defaultMessage: "Network Error! Please try again later."
        }));
        return;
    }
    if (error.response.status === 401) {
        if (error.response.data && error.response.data.message) {
            notification("error", error.response.data.message);
            store.dispatch(authActions.logout());
            history.replace("/");
            return;
        }
        antdnotify.error({
            key: "tokenError",
            message: formatMessage({
                id: "tokenError",
                defaultMessage: "Token Expired! Please logout and login again."
            })
        });
        store.dispatch(authActions.logout());
        history.replace("/");
    } else if (error.response) {
        notification("error", error.response.data.message ? error.response.data.message : error.response.data);
    } else {
        notification("error", formatMessage({
            id: "otherkError",
            defaultMessage: "Something went wrong, please try again later."
        }));
    }
};


export function clearToken() {
    localStorage.removeItem("nukleon_token");
    localStorage.removeItem("nukleon_user");
    localStorage.removeItem("nukleon_account");
}

export function getToken() {
    try {
        const idToken = localStorage.getItem("nukleon_token");
        const user = JSON.parse(localStorage.getItem("nukleon_user"));
        return new Map({idToken, user});
    } catch (err) {
        clearToken();
        return new Map();
    }
}

export function timeDifference(givenTime) {
    givenTime = new Date(givenTime);
    const milliseconds = new Date().getTime() - givenTime.getTime();
    const numberEnding = number => {
        return number > 1 ? "s" : "";
    };
    const number = num => (num > 9 ? "" + num : "0" + num);
    const getTime = () => {
        let temp = Math.floor(milliseconds / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            const month = number(givenTime.getUTCMonth() + 1);
            const day = number(givenTime.getUTCDate());
            const year = givenTime.getUTCFullYear() % 100;
            return `${day}-${month}-${year}`;
        }
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            if (days < 28) {
                return days + " day" + numberEnding(days);
            } else {
                const months = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ];
                const month = months[givenTime.getUTCMonth()];
                const day = number(givenTime.getUTCDate());
                return `${day} ${month}`;
            }
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return `${hours} hour${numberEnding(hours)} ago`;
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return `${minutes} minute${numberEnding(minutes)} ago`;
        }
        return "a few seconds ago";
    };
    return getTime();
}

export const calcCentury = (start, end) => {
    const averageT = (+start + +end) / 2;
    const year = getYear(new Date(averageT));
    return `${Math.floor((year - 1) / 100) + 1}th century`;
};

export const calcCentralDate = (start, end) => ((+start + +end) / 2);

export const calcGenericTimecode = (start, end) => {
    const mStartH = getHours(new Date(start));
    const mEndH = getHours(new Date(end));

    if ((mStartH > 6) && (mEndH > 6) && (mStartH < 12) && (mEndH < 12)) {
        return 'am';
    }

    if ((mStartH > 12) && (mStartH < 18) && (mEndH < 18) && (mEndH > 12)) {
        return 'pm';
    }

    if (((mStartH > 18) || (mStartH < 6)) && ((mEndH < 6) || (mEndH > 18))) {
        return 'night';
    }
    return 'day';
};

export const calcAccurateTimecode = (start, end) => {
    const mStart = moment(start);
    const mEnd = moment(end);
    return `Between ${mStart.format('HH:MM:SS')} and ${mEnd.format('HH:MM:SS')}`
};

export const lessThenDay = (start, end) => (+end - +start < MILISECOND_IN_DAY)

export const sumTime = (listEvents) => listEvents.reduce((acc, item) => {
    return acc + +item.start + +item.end;
}, 0);


export const getThematicsById = (thematics = [], ids = []) =>
    ids.map((id) => thematics.find((them) => id === them._id));

export const parseArrayByQuery = (arr = [], query = '') => arr.filter((item) => item.includes(query));

export const parseVisItems = (items, visItems) => {
    for (const item of items) {
        if (item.timedatas) {
            parseVisItem(item._id, item.timedatas, visItems);
        }
        if (item.children) {
            parseVisItems(item.children, visItems);
        }
    }
}

export const parseVisItem = (item_id, timeDatas, visItems) => {
    for (const td of timeDatas) {
        let visItem = visItems.find(item => item.id === td._id);
        if(!visItem) {
            visItem = createVisItem(td, item_id);
        } else {
            for(let i = 0; i < 100; i++) {
                const duplicateVisItem = visItems.find(item => item.id === td.id + "_" + i);
                if(!duplicateVisItem) {
                    visItem = Object.assign({}, visItem);
                    visItem.id = td.id + "_" + i;
                    visItem.group = item_id + "_" + i;
                    break;
                }
            }
        }
        visItems.push(visItem);
    }
};

export const createVisItem = (ev, item_id) => {
    return {
        ...ev,
        id: ev._id,
        start:ev.start_date,
        start_date: moment(ev.start_date),
        end: ev.end_date,
        end_date: moment(ev.end_date),
        content: " ",
        title: ev.short_title,
        type: "range",
        temporal_type: ev.temporal_type,
        timeDataType: ev.type ? ev.type : "event",
        group: item_id,
        subgroup: getSubgroupVisITem(ev.type ? ev.type : "event"),
        className: getClassNameVisItem(ev.type ? ev.type : "event", ev)
    };
}

export const getSubgroupVisITem = (timeDataType) => {
    return timeDataType === 'event' ? 1 : 2;
}

export const getClassNameVisItem = (timeDataType, timeData) => {
    if (timeData.temporal_type === 'PARTIAL_ABSOLUTE') {
        if (timeData.partial_absolute === 'DURING_YEAR' || timeData.partial_absolute === 'BETWEEN_YEARS') {
            return timeDataType === 'event' ? "vis-event-row" : "vis-state-row";
        } else if (timeData.partial_absolute === 'AFTER_YEAR') {
            return timeDataType === 'event' ? "vis-event-partial-right" : "vis-state-partial-right";
        } else if (timeData.partial_absolute === 'BEFORE_YEAR') {
            return timeDataType === 'event' ? "vis-event-partial-left" : "vis-state-partial-left";
        } else {
            return timeDataType === 'event' ? "vis-event-partial-both" : "vis-state-partial-both";
        }
    } else if (timeData.temporal_type === 'CENTRAL') {
        return timeDataType === 'event' ? "vis-event-central" : "vis-state-central";
    } else {
        return timeDataType === 'event' ? "vis-event-row" : "vis-state-row";
    }
}

export const createVisItems = (items) => {
    const visItems = [];
    parseVisItems(items, visItems);
    return visItems;
};

export const createVisGroups = (items) => {
    const groups = [];
    parseVisItemsToGroup(items, groups, 1)
    return groups;
};

export const parseVisItemsToGroup = (items, groups, treeLevel = 1, parent= undefined) => {
    for (const item of items) {
        let group = groups.find(g => g.id === item._id);
        if(!group) {
            group = {
                id: item._id,
                _id: item._id,
                content: item.name,
                treeLevel: treeLevel,
                nestedGroups: [],
                showNested: true
            };
        } else {
            for(let i = 0; i < 100; i++) {
                const duplicateGroup = groups.find(g => g.id === group.id + "_" + i);
                if(!duplicateGroup) {
                    group = Object.assign({}, group);
                    group.id = group.id + "_" + i;
                    break;
                }
            }
        }
        groups.push(group);
        if(parent) {
            parent.nestedGroups.push(group.id);
        }
        if (item.children) {
            parseVisItemsToGroup(item.children, groups, treeLevel + 1, group);
        }
    }
};

export const genericTemporalData = (timeData) => {
    const {start_date, end_date} = timeData;
    const start_date_millis = start_date.valueOf();
    const end_date_millis = end_date.valueOf();

    if (timeData.temporal_type === 'FULL_ABSOLUTE') {
        const centry = calcCentury(start_date_millis, end_date_millis);
        const isLessThenDay = lessThenDay(start_date_millis, end_date_millis);
        return (
            {
                central_date: moment(calcCentralDate(start_date_millis, end_date_millis)),
                start_date: start_date,
                start: start_date,
                end_date: end_date,
                end: end_date,
                period: centry,
                accurate_timecode: isLessThenDay
                    ? calcAccurateTimecode(start_date_millis, end_date_millis)
                    : null,
                generic_timecode: isLessThenDay
                    ? calcGenericTimecode(start_date_millis, end_date_millis)
                    : null
            }
        )
    } else if (timeData.temporal_type === 'PARTIAL_ABSOLUTE') {
        let start;
        let end;
        if(!timeData.partial_absolute) {
            timeData.partial_absolute = 'APPROX_YEAR';
        }
        if (timeData.partial_absolute === 'APPROX_YEAR' || timeData.partial_absolute === 'DURING_YEAR') {
            start = moment(start_date).startOf("year");
            end = moment(start_date).endOf("year");
        } else if (timeData.partial_absolute === 'BETWEEN_YEARS') {
            start = moment(start_date).startOf("year");
            end = moment(end_date).endOf("year");
        } else if (timeData.partial_absolute === 'BEFORE_YEAR') {
            end = moment(end_date).endOf("year");
            start = moment(end_date).subtract(1000, 'y').startOf("year");
        } else if (timeData.partial_absolute === 'AFTER_YEAR') {
            start = moment(start_date).startOf("year");
            end = moment(start_date).add(1000, 'y').endOf("year");
        }
        return (
            {
                partial_absolute: timeData.partial_absolute ? timeData.partial_absolute : 'APPROX_YEAR',
                start_date: start,
                start: start,
                end_date: end,
                end: end
            }
        )
    } else if (timeData.temporal_type === 'CENTRAL') {
        let central = timeData.central_date ? moment(timeData.central_date) : moment(start_date);
        let start = moment(central);
        let end = moment(central).add(1, 'd');
        if(timeData.central_unit && timeData.central_value) {
            if(timeData.central_unit === 'DAYS' ) {
                start.subtract(timeData.central_value / 2, 'd');
                end.add(timeData.central_value / 2, 'd');
            } else if(timeData.central_unit === 'MONTHS' ) {
                start.subtract(timeData.central_value / 2, 'M');
                end.add(timeData.central_value / 2, 'M');
            } else if(timeData.central_unit === 'YEARS' ) {
                start.subtract(timeData.central_value / 2, 'y');
                end.add(timeData.central_value / 2, 'y');
            } else if(timeData.central_unit === 'CENTURIES' ) {
                start.subtract(timeData.central_value * 50, 'y');
                end.add(timeData.central_value * 50, 'y');
            }
        }
        let leftItem = undefined;
        let rightItem = undefined;
        if(timeData.lower_unit && timeData.lower_value) {
            leftItem = {
                id: "-1_" + timeData._id,
                start: moment(start),
                end: moment(start),
                end_date: moment(start),
                content: " ",
                title: timeData.short_title + " - lower fork",
                type: "range",
                temporal_type: timeData.temporal_type,
                timeDataType: timeData.timeDataType,
                group: timeData.group,
                subgroup: timeData.subgroup,
                className: timeData.timeDataType === 'event' ? "vis-event-partial-left" : "vis-state-partial-left"
            }
            if(timeData.lower_unit === 'DAYS' ) {
                leftItem.start.subtract(timeData.lower_value, 'd');
            } else if(timeData.lower_unit === 'MONTHS' ) {
                leftItem.start.subtract(timeData.lower_value, 'M');
            } else if(timeData.lower_unit === 'YEARS' ) {
                leftItem.start.subtract(timeData.lower_value, 'y');
            } else if(timeData.lower_unit === 'CENTURIES' ) {
                leftItem.start.subtract(timeData.lower_value * 100, 'y');
            }
            leftItem.start_date = leftItem.start;
        }
        if(timeData.higher_unit && timeData.higher_value) {
            rightItem = {
                id: "1_" + timeData._id,
                start: moment(end),
                end: moment(end),
                start_date: moment(end),
                content: " ",
                title: timeData.short_title + " - higher fork",
                type: "range",
                temporal_type: timeData.temporal_type,
                timeDataType: timeData.timeDataType,
                group: timeData.group,
                subgroup: timeData.subgroup,
                className: timeData.timeDataType === 'event' ? "vis-event-partial-right" : "vis-state-partial-right"
            }
            if(timeData.higher_unit === 'DAYS' ) {
                rightItem.end.add(timeData.higher_value, 'd');
            } else if(timeData.higher_unit === 'MONTHS' ) {
                rightItem.end.add(timeData.higher_value, 'M');
            } else if(timeData.higher_unit === 'YEARS' ) {
                rightItem.end.add(timeData.higher_value, 'y');
            } else if(timeData.higher_unit === 'CENTURIES' ) {
                rightItem.end.add(timeData.higher_value * 100, 'y');
            }
            rightItem.end_date = rightItem.end;
        }
        return (
            {
                central_date: central,
                start_date: start,
                start: start,
                end_date: end,
                end: end,
                leftItem,
                rightItem
            }
        )
    }
};

export const transformToTreeData = (items, id) => {
    const treeData = [];
    for (const item of items) {
        const data = {};
        data.title = item.name;
        data.value = item._id;
        data.key = item._id + "-" + (item.occurence ? item.occurence : "0")
        if (item.children) {
            data.children = transformToTreeData(item.children, id);
        }
        if (item._id !== id) {
            treeData.push(data);
        }
    }
    return treeData;
}

export const findItemInTree = (items, id) => {
    for (const item of items) {
        if (item._id === id) {
            return true;
        }
        if (item.children && findItemInTree(item.children, id)) {
            return true;
        }
    }
    return false
}