import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({ idToken: null, user: null, authenticating: false, selected_account: null });

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return state.set('authenticating', true);
    case actions.LOGIN_ERROR:
      return state.set('authenticating', false);
    case actions.UPDATE_USER:
      return state.set('user', action.user);
    case actions.SWITCH_ACCOUNT:
      return state.set('selected_account', action.account);
    case actions.LOGIN_SUCCESS:
      return state
              .set('idToken', action.token)
              .set('user', action.user)
              .set('authenticating', false);
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
