const hostname = window && window.location && window.location.hostname;

let apiUrl =
    window.location.protocol === "https:" ? "https://" + hostname + ":8082/v1/" : "http://" + hostname + ":8081/v1/";

/*if (hostname === "relics-dev.exatronix.com") {
    apiUrl = "http://relics-dev.exatronix.com:8081/v1/";
}*/

if (hostname === "archane.brussels") {
    apiUrl = "https://archane.brussels:8443/v1/";
}

export default {
    apiUrl
};

const siteConfig = {
    siteName: "ARCHANE",
    siteIcon: "ion-flash",
    footerText: "Archane ©2020-2022 Created by SpatioData, Inc"
};
const themeConfig = {
    topbar: "themedefault",
    sidebar: "themedefault",
    layout: "themedefault",
    theme: "themedefault"
};
const language = "english";
export { siteConfig, language, themeConfig };
