import antdNl from 'antd/lib/locale-provider/nl_NL';
import nlMessages from '../locales/nl_NL.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const NlLang = {
  messages: {
    ...nlMessages,
  },
  antd: antdNl,
  locale: 'nl-NL',
};
export default NlLang;
