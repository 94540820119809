import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { connectRouter } from 'connected-react-router'
import { routerMiddleware } from 'connected-react-router'
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import reducers from "../redux/reducers";
import rootSaga from "../redux/sagas";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["Roles", "ItemsTableColumns"]
};
// const persistedReducer = persistReducer(persistConfig, rootReducer)

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const store = createStore(
    persistReducer(
        persistConfig,
        combineReducers({
            ...reducers,
            router: connectRouter(history)
        })
    ),
    compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);
let persistor = persistStore(store);
export { store, history, persistor };
